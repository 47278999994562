//type "make app" to upload folders to public link (must on internal network)
//type "gcloud app deploy" to upload app to google gcloud
//type "npm start" to run sandbox

import React from "react";
import ReactDOM from "react-dom";
import "../App.css";

var devices = [
  {
    model: "Z3",
    devices: 5,
    maxSpeed: 100,
    wan1: '1G',
    wan2: '0',
    sfp: false,
    poe: true,
    vpnSpeed: 50,
    vpnTunnels: 5,
    security: 0,
    rpsu: null,
    rfan: false,
    wireless: true,
    sim: null,
    virtual: false
  }, {
    model: "Z3C",
    devices: 5,
    maxSpeed: 100,
    wan1: '1G',
    wan2: '0',
    sfp: false,
    poe: true,
    vpnSpeed: 50,
    vpnTunnels: 5,
    security: 0,
    rpsu: null,
    rfan: false,
    wireless: true,
    sim: true,
    virtual: false
  }, {
    model: "Z4",
    devices: 5,
    maxSpeed: 500,
    wan1: '1G',
    wan2: '0',
    sfp: false,
    poe: true,
    vpnSpeed: 250,
    vpnTunnels: 5,
    security: 300,
    rpsu: null,
    rfan: false,
    wireless: true,
    sim: false,
    virtual: false
  }, {
    model: "Z4C",
    devices: 15,
    maxSpeed: 500,
    wan1: '1G',
    wan2: '0',
    sfp: false,
    poe: true,
    vpnSpeed: 250,
    vpnTunnels: 5,
    security: 300,
    rpsu: null,
    rfan: false,
    wireless: true,
    sim: false,
    virtual: false
  },
  // {
  //   model: "MX64",
  //   devices: 50,
  //   maxSpeed: 250,
  //   wan1: '1G',
  //   wan2: '0',
  //   sfp: false,
  //   poe: false,
  //   vpnSpeed: 100,
  //   vpnTunnels: 50,
  //   security: 200,
  //   rpsu: null,
  //   rfan: false,
  //   wireless: false,
  //   sim: null,
  //   virtual: false
  // }, {
  //   model: "MX64W",
  //   devices: 50,
  //   maxSpeed: 250,
  //   wan1: '1G',
  //   wan2: '0',
  //   sfp: false,
  //   poe: false,
  //   vpnSpeed: 100,
  //   vpnTunnels: 50,
  //   security: 200,
  //   rpsu: null,
  //   rfan: false,
  //   wireless: true,
  //   sim: null,
  //   virtual: false
  // }, 
  {
    model: "MX67",
    devices: 50,
    maxSpeed: 600,
    wan1: '1G',
    wan2: '0',
    sfp: false,
    poe: false,
    vpnSpeed: 200,
    vpnTunnels: 50,
    security: 400,
    rpsu: null,
    rfan: false,
    wireless: false,
    sim: null,
    virtual: false
  }, {
    model: "MX67W",
    devices: 50,
    maxSpeed: 600,
    wan1: '1G',
    wan2: '0',
    sfp: false,
    poe: false,
    vpnSpeed: 200,
    vpnTunnels: 50,
    security: 400,
    rpsu: null,
    rfan: false,
    wireless: true,
    sim: null,
    virtual: false
  }, {
    model: "MX67C",
    devices: 50,
    maxSpeed: 600,
    wan1: '1G',
    wan2: '0',
    sfp: false,
    poe: false,
    vpnSpeed: 200,
    vpnTunnels: 50,
    security: 400,
    rpsu: null,
    rfan: false,
    wireless: false,
    sim: true,
    virtual: false
  }, {
    model: "MX68",
    devices: 50,
    maxSpeed: 600,
    wan1: '1G',
    wan2: '0',
    sfp: false,
    poe: true,
    vpnSpeed: 200,
    vpnTunnels: 50,
    security: 400,
    rpsu: null,
    rfan: false,
    wireless: false,
    sim: null,
    virtual: false
  }, {
    model: "MX68W",
    devices: 50,
    maxSpeed: 600,
    wan1: '1G',
    wan2: '0',
    sfp: false,
    poe: true,
    vpnSpeed: 200,
    vpnTunnels: 50,
    security: 400,
    rpsu: null,
    rfan: false,
    wireless: true,
    sim: null,
    virtual: false
  }, {
    model: "MX68CW",
    devices: 50,
    maxSpeed: 600,
    wan1: '1G',
    wan2: '0',
    sfp: false,
    poe: true,
    vpnSpeed: 200,
    vpnTunnels: 50,
    security: 400,
    rpsu: null,
    rfan: false,
    wireless: true,
    sim: true,
    virtual: false
  }, {
    model: "MX75",
    devices: 200,
    maxSpeed: 1000,
    wan1: '1G',
    wan2: '1G SFP',
    sfp: true,
    poe: true,
    vpnSpeed: 500,
    vpnTunnels: 75,
    security: 1000,
    rpsu: null,
    rfan: false,
    wireless: false,
    sim: null,
    virtual: false
  },
  // {
  //   model: "MX84",
  //   devices: 200,
  //   maxSpeed: 500,
  //   wan: 1,
  //   poe: false,
  //   vpnSpeed: 250,
  //   vpnTunnels: 100,
  //   security: 320,
  //   rpsu: null,
  //   rfan: false,
  //   wireless: false,
  //   sim: null,
  //   virtual: false
  // },
  {
    model: "MX85",
    devices: 500,
    maxSpeed: 1000,
    wan1: '1G',
    wan2: '1G SFP',
    poe: true,
    vpnSpeed: 500,
    vpnTunnels: 100,
    security: 750,
    rpsu: null,
    rfan: false,
    wireless: false,
    sim: null,
    virtual: false
  }, {
    model: "MX95",
    devices: 500,
    maxSpeed: 2000,
    wan1: '10G SFP+',
    wan2: '2.5G',
    poe: true,
    vpnSpeed: 800,
    vpnTunnels: 250,
    security: 1000,
    rpsu: null,
    rfan: false,
    wireless: false,
    sim: null,
    virtual: false
  },
  // {
  //   model: "MX100",
  //   devices: 500,
  //   maxSpeed: 750,
  //   wan: 1,
  //   poe: false,
  //   vpnSpeed: 500,
  //   vpnTunnels: 250,
  //   security: 650,
  //   rpsu: null,
  //   rfan: false,
  //   wireless: false,
  //   sim: null,
  //   virtual: false
  // },
  {
    model: "MX105",
    devices: 750,
    maxSpeed: 3000,
    wan1: '10G SFP+',
    wan2: '2.5G',
    poe: true,
    vpnSpeed: 1000,
    vpnTunnels: 500,
    security: 2500,
    rpsu: true,
    rfan: false,
    wireless: false,
    sim: null,
    virtual: false
  }, {
    model: "MX250",
    devices: 2000,
    maxSpeed: 4000,
    wan1: '10G SFP+',
    wan2: '0',
    poe: false,
    vpnSpeed: 1000,
    vpnTunnels: 1000,
    security: 3000,
    rpsu: true,
    rfan: true,
    wireless: false,
    sim: null,
    virtual: false
  }, {
    model: "MX450",
    devices: 10000,
    maxSpeed: 5000,
    wan1: '10G SFP+',
    wan2: '0',
    poe: false,
    vpnSpeed: 2000,
    vpnTunnels: 1500,
    security: 6500,
    rpsu: true,
    rfan: true,
    wireless: false,
    sim: null,
    virtual: false
  }, {
    model: "vMX Small",
    devices: 999999,
    maxSpeed: 200,
    wan: 0,
    poe: false,
    vpnSpeed: 200,
    vpnTunnels: 50,
    security: 0,
    rpsu: false,
    rfan: false,
    wireless: false,
    sim: null,
    virtual: true
  }, {
    model: "vMX Medium",
    devices: 999999,
    maxSpeed: 500,
    wan: 0,
    poe: false,
    vpnSpeed: 500,
    vpnTunnels: 250,
    security: 0,
    rpsu: false,
    rfan: false,
    wireless: false,
    sim: null,
    virtual: true
  }, {
    model: "vMX Large",
    devices: 999999,
    maxSpeed: 1000,
    wan: 0,
    poe: false,
    vpnSpeed: 1000,
    vpnTunnels: 1000,
    security: 0,
    rpsu: false,
    rfan: false,
    wireless: false,
    sim: null,
    virtual: true
  }
];
var securityToggle = false;

class MXPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devicesMain: null,
      devices: null,
      devicesRange: null,

      vpnMain: null,
      vpnCount: null,
      vpnRange: null,

      speedMain: null,
      speedCount: null,
      speedRange: null,

      securityToggle: null,

      tunnels: null,
      uplink: null,
      transceiver: null,

      switchModel: null,
      wishVisible: null,
      poe: null,
      rpsu: null,
      rfan: null,

      wireless: null,
      sim: null,

      merakiInsight: null,
      miTerm: null,
      licenseTerm: null,
      license: null,

      clickedSwitchModel: null,
      clickedSwitchModelDetails: null
    };

    //Needed for keystroke changes for input boxes
    this.handleChange = this.handleChange.bind(this);
    this.handleVPNChange = this.handleVPNChange.bind(this);
    this.handleSpeedChange = this.handleSpeedChange.bind(this);
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ SWITCHES @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  renderDeviceInfo(header, switchModels) {
    return (<div>
      <p>
        <h6 className="switchInfoHeader">{header + ": "}</h6>
        {switchModels}
      </p>
    </div>);
  }

  renderSwitchModelDetails(model, ports, poe, mgig) {
    return (<div onClick={this.state.clickedSwitchModel != model
      ? () => this.setState({ clickedSwitchModel: model, clickedSwitchModelDetails: ports })
      : () => this.setState({ clickedSwitchModel: null })
    } class="switchModelDetailContainer">
      {
        this.state.poe == true && poe == 0
          ? ("")
          : this.state.poeType <= poe && this.state.mgigSpeed <= mgig
            ? (<a className="switchModelDetail">{model}</a>)
            : ("")
      }
    </div>);
  }

  renderSwitchModelDetailsClicked() {
    return (<div>
      {
        this.state.clickedSwitchModel != null
          ? (<div className="secondaryOptionsContainer secondaryOptionsContainerModel">
            <h4>{this.state.clickedSwitchModel}</h4>
            <h5 className="commaToNewLine">
              {this.state.clickedSwitchModelDetails.replace(/~/g, "\n")}
            </h5>
          </div>)
          : ("")
      }
    </div>);
  }

  renderSecurityLicenseSKU(model) {
    if (this.state.switchModel == "MX75" || this.state.switchModel == "MX85" || this.state.switchModel == "MX95" || this.state.switchModel == "MX105") {
      if (this.state.licenseTerm == null && this.state.license == "SDW") {
        return <p>{"1 x LIC-" + model + "-" + this.state.license + "-xY (x = 1, 3, 5, 7, 10 years)"}</p>
      }

      if (this.state.licenseTerm != null && this.state.license == "SDW") {
        return <p>{"1 x LIC-" + model + "-" + this.state.license + "-" + this.state.licenseTerm + "Y"}</p>
      }
      if (this.state.licenseTerm == null && this.state.license != null) {
        return <p>{"1 x LIC-" + model + "-" + this.state.license + "-xY (x = 1, 3, 5, 7, 10 years)"}</p>
      }

      if (this.state.licenseTerm != null) {
        return <p>{"1 x LIC-" + model + "-" + this.state.license + "-" + this.state.licenseTerm + "Y"}</p>
      } else {
        return null
      }

    } else {
      if (this.state.licenseTerm == null && this.state.license == "SDW") {
        return <p>{"1 x LIC-" + model + "-" + this.state.license + "-xY (x = 1, 3, 5, 7, 10 years)"}</p>
      }

      if (this.state.licenseTerm != null && this.state.license == "SDW") {
        return <p>{"1 x LIC-" + model + "-" + this.state.license + "-" + this.state.licenseTerm + "Y"}</p>
      }
      if (this.state.licenseTerm == null && this.state.license != null) {
        return <p>{"1 x LIC-" + model + "-" + this.state.license + "-xYR (x = 1, 3, 5, 7, 10 years)"}</p>
      }

      if (this.state.licenseTerm != null) {
        return <p>{"1 x LIC-" + model + "-" + this.state.license + "-" + this.state.licenseTerm + "YR"}</p>
      } else {
        return null
      }
    }

  }

  renderVirtualLicenseSKU(size) {
    if (this.state.switchModel == "vMX Small" || this.state.switchModel == "vMX Medium" || this.state.switchModel == "vMX Large") {
      if (this.state.licenseTerm == null && this.state.license == "Virtual") {
        return <p>{"1 x LIC-VMX-" + size + "-ENT-xY (x = 1, 3, 5 years)"}</p>
      }
      if (this.state.licenseTerm != null && this.state.license == "Virtual") {
        return <p>{"1 x LIC-VMX-" + size + "-ENT-" + this.state.licenseTerm + "Y"}</p>
      }
      if (this.state.licenseTerm == null && this.state.license != null) {
        return <p>{"1 x LIC-VMX-" + size + "-ENT-" + this.state.licenseTerm + "Y"}</p>
      }
      if (this.state.licenseTerm != null) {
        return <p>{"1 x LIC-VMX-" + size + "-ENT-" + this.state.licenseTerm + "Y"}</p>
      } else {
        return <p>{"1 x LIC-VMX-" + size + "-ENT-xY (x = 1, 3, 5 years)"}</p>
      }
    }
  }

  renderMILicenseSKU(size) {
    return (<div>
      {
        this.state.miTerm != null
          ? <p>{"1 x LIC-MI-" + size + "-" + this.state.miTerm + "YR"}</p>
          : null
      }
    </div>);
  }

  renderTransceiverSKU() {
    if (this.state.transceiver != null) {
      return <p>{'? x ' + this.state.transceiver}</p>
    } else {
      return null;
    }
  }

  renderSwitchModel(model) {
    return (<div className={model + " switchPictureContainer"}>
      <div onClick={this.state.switchModel != model
        ? () => this.setState({ switchModel: model, clickedSwitchModel: null })
        : () => this.setState({ switchModel: null })
      } className={"switchModel" + model}>
        <h6 className={this.state.switchModel == model
          ? "selectedSwitchHeader"
          : ""
        }>
          {model}
        </h6>
        {
          model == "MX84" || model == "MX85" || model == "MX95" || model == "MX100" || model == "MX105" || model == "MX250" || model == "MX450" || model == "vMX Medium" || model == "vMX Large"
            ? (<img src={"/img/mx/" + model + ".png"} alt={model} className="modelIconFull modelIcon" />)
            : model == "Z3" || model == "Z3C" || model == "Z4" || model == "Z4C"
              ? (<img src={"/img/mx/" + model + ".png"} alt={model} className="modelIconLarge modelIcon" />)
              : (<img src={"/img/mx/" + model + ".png"} alt={model} className="modelIconHalf modelIcon" />)
        }
      </div>
      <div className={"switchInfo"}>
        {
          this.state.switchModel == "Z3" && model == this.state.switchModel
            ? <div><div className="switchInfoSelected">
              {this.renderDeviceInfo("WAN Ports", "1 x 1G")}
              {this.renderDeviceInfo("LAN Ports", "4 x 1G (1 x 802.3af PoE)")}
              {this.renderDeviceInfo("Client Recommendation", "5 Devices")}
              {this.renderDeviceInfo("Recommended Max VPN Tunnels", "5 Tunnels")}
              {this.renderDeviceInfo("Throughput", "100 Mbps")}
              <h6 className="switchInfoHeader margin10 orderingInformation">
                Ordering Information:
              </h6>
              <p>{"1 x " + model + "-HW"}</p>
              {this.renderSecurityLicenseSKU(model)}
              {this.renderMILicenseSKU("XS")}
              {this.renderTransceiverSKU()}
            </div>
              <div className='datasheetContainer'>
                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/z-series-datasheet/?file">Datasheet</a>
                {/* <p className='datasheetDivider'> | </p>
                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MI/Intro/Meraki_Insight_Introduction">Installation</a> */}
              </div>
            </div>
            : this.state.switchModel == "Z3C" && model == this.state.switchModel
              ? <div><div className="switchInfoSelected">
                {this.renderDeviceInfo("WAN Ports", "1 x 1G")}
                {this.renderDeviceInfo("LAN Ports", "4 x 1G (1 x 802.3af PoE)")}
                {this.renderDeviceInfo("Cellular", "CAT3 LTE Cellular Modem")}
                {this.renderDeviceInfo("Client Recommendation", "5 Devices")}
                {this.renderDeviceInfo("Recommended Max VPN Tunnels", "5 Tunnels")}
                {this.renderDeviceInfo("Throughput", "100 Mbps")}
                <h6 className="switchInfoHeader margin10 orderingInformation">
                  Ordering Information:
                </h6>
                <p>{"1 x " + model + "-HW"}</p>
                {this.renderSecurityLicenseSKU(model)}
                {this.renderMILicenseSKU("XS")}
                {this.renderTransceiverSKU()}
              </div>
                <div className='datasheetContainer'>
                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/z-series-datasheet/?file">Datasheet</a>
                  {/* <p className='datasheetDivider'> | </p>
                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MI/Intro/Meraki_Insight_Introduction">Installation</a> */}
                </div>
              </div>
              : this.state.switchModel == "Z4" && model == this.state.switchModel
              ? <div><div className="switchInfoSelected">
                {this.renderDeviceInfo("WAN Ports", "1 x 1G")}
                {this.renderDeviceInfo("LAN Ports", "4 x 1G (1 x 802.3at PoE)")}
                {this.renderDeviceInfo("Client Recommendation", "5 Devices")}
                {this.renderDeviceInfo("Recommended Max VPN Tunnels", "5 Tunnels")}
                {this.renderDeviceInfo("Throughput", "500 Mbps")}
                {this.renderDeviceInfo("Advanced Security Throughput", "300 Mbps")}

                <h6 className="switchInfoHeader margin10 orderingInformation">
                  Ordering Information:
                </h6>
                <p>{"1 x " + model + "-HW"}</p>
                {this.renderSecurityLicenseSKU(model)}
                {this.renderMILicenseSKU("XS")}
                {this.renderTransceiverSKU()}
              </div>
                <div className='datasheetContainer'>
                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/Z4_Datasheet">Datasheet</a>
                  {/* <p className='datasheetDivider'> | </p>
                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MI/Intro/Meraki_Insight_Introduction">Installation</a> */}
                </div>
              </div>
              : this.state.switchModel == "Z4C" && model == this.state.switchModel
              ? <div><div className="switchInfoSelected">
                {this.renderDeviceInfo("WAN Ports", "1 x 1G")}
                {this.renderDeviceInfo("LAN Ports", "4 x 1G (1 x 802.3at PoE)")}
                {this.renderDeviceInfo("Client Recommendation", "15 Devices")}
                {this.renderDeviceInfo("Recommended Max VPN Tunnels", "5 Tunnels")}
                {this.renderDeviceInfo("Throughput", "500 Mbps")}
                {this.renderDeviceInfo("Advanced Security Throughput", "300 Mbps")}

                <h6 className="switchInfoHeader margin10 orderingInformation">
                  Ordering Information:
                </h6>
                <p>{"1 x " + model + "-HW"}</p>
                {this.renderSecurityLicenseSKU(model)}
                {this.renderMILicenseSKU("XS")}
                {this.renderTransceiverSKU()}
              </div>
                <div className='datasheetContainer'>
                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/Z4C_Datasheet">Datasheet</a>
                  {/* <p className='datasheetDivider'> | </p>
                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MI/Intro/Meraki_Insight_Introduction">Installation</a> */}
                </div>
              </div>
                  : this.state.switchModel == "MX67" && model == this.state.switchModel
                    ? <div><div className="switchInfoSelected">
                      {this.renderDeviceInfo("WAN Ports", "1 x 1G")}
                      {this.renderDeviceInfo("LAN Ports", "4 x 1G (LAN4 can be second WAN port)")}
                      {this.renderDeviceInfo("Client Recommendation", "50 Devices")}
                      {this.renderDeviceInfo("Recommended Max VPN Tunnels", "50 Tunnels")}
                      {this.renderDeviceInfo("Stateful Firewall Throughput", "600 Mbps")}
                      {this.renderDeviceInfo("Advanced Security Throughput", "400 Mbps")}
                      <h6 className="switchInfoHeader margin10 orderingInformation">
                        Ordering Information:
                      </h6>
                      <p>{"1 x " + model + "-HW"}</p>
                      {this.renderSecurityLicenseSKU(model)}
                      {this.renderMILicenseSKU("S")}
                      {this.renderTransceiverSKU()}
                    </div>
                      <div className='datasheetContainer'>
                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/MX67_and_MX68_Datasheet">Datasheet</a>
                        <p className='datasheetDivider'> | </p>
                        <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX67%2F%2FMX68_Installation_Guide">Installation</a>
                      </div>
                    </div>
                    : this.state.switchModel == "MX67W" && model == this.state.switchModel
                      ? <div><div className="switchInfoSelected">
                        {this.renderDeviceInfo("WAN Ports", "1 x 1G")}
                        {this.renderDeviceInfo("LAN Ports", "4 x 1G (LAN4 can be second WAN port)")}
                        {this.renderDeviceInfo("Wireless", "802.11ac Wave 2")}
                        {this.renderDeviceInfo("Client Recommendation", "50 Devices")}
                        {this.renderDeviceInfo("Recommended Max VPN Tunnels", "50 Tunnels")}
                        {this.renderDeviceInfo("Stateful Firewall Throughput", "600 Mbps")}
                        {this.renderDeviceInfo("Advanced Security Throughput", "400 Mbps")}
                        <h6 className="switchInfoHeader margin10 orderingInformation">
                          Ordering Information:
                        </h6>
                        <p>{"1 x " + model + "-HW"}</p>
                        {this.renderSecurityLicenseSKU(model)}
                        {this.renderMILicenseSKU("S")}
                        {this.renderTransceiverSKU()}
                      </div>
                        <div className='datasheetContainer'>
                          <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/MX67_and_MX68_Datasheet">Datasheet</a>
                          <p className='datasheetDivider'> | </p>
                          <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX67%2F%2FMX68_Installation_Guide">Installation</a>
                        </div>
                      </div>
                      : this.state.switchModel == "MX67C" && model == this.state.switchModel
                        ? <div><div className="switchInfoSelected">
                          {this.renderDeviceInfo("WAN Ports", "1 x 1G")}
                          {this.renderDeviceInfo("LAN Ports", "4 x 1G (LAN4 can be second WAN port)")}
                          {this.renderDeviceInfo("Cellular", "CAT6 LTE Cellular Modem")}
                          {this.renderDeviceInfo("Client Recommendation", "50 Devices")}
                          {this.renderDeviceInfo("Recommended Max VPN Tunnels", "50 Tunnels")}
                          {this.renderDeviceInfo("Stateful Firewall Throughput", "600 Mbps")}
                          {this.renderDeviceInfo("Advanced Security Throughput", "400 Mbps")}
                          <h6 className="switchInfoHeader margin10 orderingInformation">
                            Ordering Information:
                          </h6>
                          <p>{"1 x " + model + "-HW"}</p>
                          {this.renderSecurityLicenseSKU(model)}
                          {this.renderMILicenseSKU("S")}
                          {this.renderTransceiverSKU()}
                        </div>
                          <div className='datasheetContainer'>
                            <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/MX67_and_MX68_Datasheet">Datasheet</a>
                            <p className='datasheetDivider'> | </p>
                            <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX67%2F%2FMX68_Installation_Guide">Installation</a>
                          </div>
                        </div>
                        : this.state.switchModel == "MX68" && model == this.state.switchModel
                          ? <div><div className="switchInfoSelected">
                            {this.renderDeviceInfo("WAN Ports", "2 x 1G")}
                            {this.renderDeviceInfo("LAN Ports", "10 x 1G (2 x PoE+)")}
                            {this.renderDeviceInfo("Client Recommendation", "50 Devices")}
                            {this.renderDeviceInfo("Recommended Max VPN Tunnels", "50 Tunnels")}
                            {this.renderDeviceInfo("Stateful Firewall Throughput", "600 Mbps")}
                            {this.renderDeviceInfo("Advanced Security Throughput", "400 Mbps")}
                            <h6 className="switchInfoHeader margin10 orderingInformation">
                              Ordering Information:
                            </h6>
                            <p>{"1 x " + model + "-HW"}</p>
                            {this.renderSecurityLicenseSKU(model)}
                            {this.renderMILicenseSKU("S")}
                            {this.renderTransceiverSKU()}
                          </div>
                            <div className='datasheetContainer'>
                              <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/MX67_and_MX68_Datasheet">Datasheet</a>
                              <p className='datasheetDivider'> | </p>
                              <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX67%2F%2FMX68_Installation_Guide">Installation</a>
                            </div>
                          </div>
                          : this.state.switchModel == "MX68W" && model == this.state.switchModel
                            ? <div><div className="switchInfoSelected">
                              {this.renderDeviceInfo("WAN Ports", "2 x 1G")}
                              {this.renderDeviceInfo("LAN Ports", "10 x 1G (2 x PoE+)")}
                              {this.renderDeviceInfo("Wireless", "802.11ac Wave 2")}
                              {this.renderDeviceInfo("Client Recommendation", "50 Devices")}
                              {this.renderDeviceInfo("Recommended Max VPN Tunnels", "50 Tunnels")}
                              {this.renderDeviceInfo("Stateful Firewall Throughput", "600 Mbps")}
                              {this.renderDeviceInfo("Advanced Security Throughput", "400 Mbps")}
                              <h6 className="switchInfoHeader margin10 orderingInformation">
                                Ordering Information:
                              </h6>
                              <p>{"1 x " + model + "-HW"}</p>
                              {this.renderSecurityLicenseSKU(model)}
                              {this.renderMILicenseSKU("S")}
                              {this.renderTransceiverSKU()}
                            </div>
                              <div className='datasheetContainer'>
                                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/MX67_and_MX68_Datasheet">Datasheet</a>
                                <p className='datasheetDivider'> | </p>
                                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX67%2F%2FMX68_Installation_Guide">Installation</a>
                              </div>
                            </div>
                            : this.state.switchModel == "MX68CW" && model == this.state.switchModel
                              ? <div><div className="switchInfoSelected">
                                {this.renderDeviceInfo("WAN Ports", "2 x 1G")}
                                {this.renderDeviceInfo("LAN Ports", "10 x 1G (2 x PoE+)")}
                                {this.renderDeviceInfo("Cellular", "CAT6 LTE Cellular Modem")}
                                {this.renderDeviceInfo("Wireless", "802.11ac Wave 2")}
                                {this.renderDeviceInfo("Client Recommendation", "50 Devices")}
                                {this.renderDeviceInfo("Recommended Max VPN Tunnels", "50 Tunnels")}
                                {this.renderDeviceInfo("Stateful Firewall Throughput", "600 Mbps")}
                                {this.renderDeviceInfo("Advanced Security Throughput", "400 Mbps")}
                                <h6 className="switchInfoHeader margin10 orderingInformation">
                                  Ordering Information:
                                </h6>
                                <p>{"1 x " + model + "-HW"}</p>
                                {this.renderSecurityLicenseSKU(model)}
                                {this.renderMILicenseSKU("S")}
                                {this.renderTransceiverSKU()}
                              </div>
                                <div className='datasheetContainer'>
                                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/MX67_and_MX68_Datasheet">Datasheet</a>
                                  <p className='datasheetDivider'> | </p>
                                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX67%2F%2FMX68_Installation_Guide">Installation</a>
                                </div>
                              </div>
                              : this.state.switchModel == "MX75" && model == this.state.switchModel
                                ? <div><div className="switchInfoSelected">
                                  {this.renderDeviceInfo("WAN Ports", "1 x 1G SFP, 2 x 1G")}
                                  {this.renderDeviceInfo("LAN Ports", "10 x 1G (2 x PoE)")}
                                  {this.renderDeviceInfo("Client Recommendation", "200 Devices")}
                                  {this.renderDeviceInfo("Recommended Max VPN Tunnels", "75 Tunnels")}
                                  {this.renderDeviceInfo("Stateful Firewall Throughput", "1 Gbps")}
                                  {this.renderDeviceInfo("Advanced Security Throughput", "1 Gbps")}
                                  <h6 className="switchInfoHeader margin10 orderingInformation">
                                    Ordering Information:
                                  </h6>
                                  <p>{"1 x " + model + "-HW"}</p>
                                  {this.renderSecurityLicenseSKU(model)}
                                  {this.renderMILicenseSKU("M")}
                                  {this.renderTransceiverSKU()}
                                </div>
                                  <div className='datasheetContainer'>
                                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/MX75_Datasheet">Datasheet</a>
                                    <p className='datasheetDivider'> | </p>
                                    <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX75_Hardware_Installation_Guide">Installation</a>
                                  </div>
                                </div>
                                // : this.state.switchModel == "MX84" && model == this.state.switchModel
                                //   ? <div><div className="switchInfoSelected">
                                //     {this.renderDeviceInfo("WAN Ports", "2 x 1G")}
                                //     {this.renderDeviceInfo("LAN Ports", "2 x 1G SFP, 8 x 1G")}
                                //     {this.renderDeviceInfo("Client Recommendation", "200 Devices")}
                                //     {this.renderDeviceInfo("Recommended Max VPN Tunnels", "100 Tunnels")}
                                //     {this.renderDeviceInfo("Stateful Firewall Throughput", "500 Mbps")}
                                //     {this.renderDeviceInfo("Advanced Security Throughput", "320 Mbps")}
                                //     <h6 className="switchInfoHeader margin10 orderingInformation">
                                //       Ordering Information:
                                //     </h6>
                                //     <p>{"1 x " + model + "-HW"}</p>
                                //     <p>{this.renderSecurityLicenseSKU(model)}</p>
                                //     <p>{this.renderMILicenseSKU("M")}</p>
                                //     <p>{this.renderTransceiverSKU()}</p>
                                //   </div>
                                //     <div className='datasheetContainer'>
                                //       <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/MX84_Datasheet">Datasheet</a>
                                //       <p className='datasheetDivider'> | </p>
                                //       <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX84_Installation_Guide">Installation</a>
                                //     </div>
                                //   </div>
                                  : this.state.switchModel == "MX85" && model == this.state.switchModel
                                    ? <div><div className="switchInfoSelected">
                                      {this.renderDeviceInfo("WAN Ports", "2 x 1G SFP, 2 x 1G (1x PoE+)")}
                                      {this.renderDeviceInfo("LAN Ports", "8 x 1G RJ45, 2 x 1G SFP")}
                                      {this.renderDeviceInfo("Client Recommendation", "500 Devices")}
                                      {this.renderDeviceInfo("Recommended Max VPN Tunnels", "100 Tunnels")}
                                      {this.renderDeviceInfo("Stateful Firewall Throughput", "1 Gbps")}
                                      {this.renderDeviceInfo("Advanced Security Throughput", "750 Mbps")}
                                      <h6 className="switchInfoHeader margin10 orderingInformation">
                                        Ordering Information:
                                      </h6>
                                      <p>{"1 x " + model + "-HW"}</p>
                                      {this.renderSecurityLicenseSKU(model)}
                                      {this.renderMILicenseSKU("M")}
                                      {this.renderTransceiverSKU()}
                                    </div>
                                      <div className='datasheetContainer'>
                                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/MX85_Datasheet">Datasheet</a>
                                        <p className='datasheetDivider'> | </p>
                                        <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX85_Series_Hardware_Installation_Guide">Installation</a>
                                      </div>
                                    </div>
                                    : this.state.switchModel == "MX95" && model == this.state.switchModel
                                      ? <div><div className="switchInfoSelected">
                                        {this.renderDeviceInfo("WAN Ports", "2 x 10G SFP+, 2 x 2.5GG (1x PoE+)")}
                                        {this.renderDeviceInfo("LAN Ports", "4 x 1G, 2 x 10G SFP+")}
                                        {this.renderDeviceInfo("Client Recommendation", "500 Devices")}
                                        {this.renderDeviceInfo("Recommended Max VPN Tunnels", "250 Tunnels")}
                                        {this.renderDeviceInfo("Stateful Firewall Throughput", "2 Gbps")}
                                        {this.renderDeviceInfo("Advanced Security Throughput", "2 Gbps")}
                                        <h6 className="switchInfoHeader margin10 orderingInformation">
                                          Ordering Information:
                                        </h6>
                                        <p>{"1 x " + model + "-HW"}</p>
                                        {this.renderSecurityLicenseSKU(model)}
                                       {this.renderMILicenseSKU("M")}
                                        {this.renderTransceiverSKU()}
                                      </div>
                                        <div className='datasheetContainer'>
                                          <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/MX95%2F%2F105_Datasheet">Datasheet</a>
                                          <p className='datasheetDivider'> | </p>
                                          <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX95%2F%2FMX105_Series_Hardware_Installation_Guide">Installation</a>
                                        </div>
                                      </div>
                                      // : this.state.switchModel == "MX100" && model == this.state.switchModel
                                      //   ? <div><div className="switchInfoSelected">
                                      //     {this.renderDeviceInfo("WAN Ports", "2 x 1G")}
                                      //     {this.renderDeviceInfo("LAN Ports", "2 x 1G SFP, 8 x 1G")}
                                      //     {this.renderDeviceInfo("Client Recommendation", "500 Devices")}
                                      //     {this.renderDeviceInfo("Recommended Max VPN Tunnels", "250 Tunnels")}
                                      //     {this.renderDeviceInfo("Stateful Firewall Throughput", "750 Mbps")}
                                      //     {this.renderDeviceInfo("Max Security Throughput", "650 Mbps")}
                                      //     <h6 className="switchInfoHeader margin10 orderingInformation">
                                      //       Ordering Information:
                                      //     </h6>
                                      //     <p>{"1 x " + model + "-HW"}</p>
                                      //     <p>{this.renderSecurityLicenseSKU(model)}</p>
                                      //     <p>{this.renderMILicenseSKU("M")}</p>
                                      //     <p>{this.renderTransceiverSKU()}</p>
                                      //   </div>
                                      //     <div className='datasheetContainer'>
                                      //       <a className='datasheet' target="_blank" rel="noopener noreferrer" href="">Datasheet</a>
                                      //       <p className='datasheetDivider'> | </p>
                                      //       <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX100_Installation_Guide">Installation</a>
                                      //     </div>
                                      //   </div>
                                        : this.state.switchModel == "MX105" && model == this.state.switchModel
                                          ? <div><div className="switchInfoSelected">
                                            {this.renderDeviceInfo("WAN Ports", "2 x 10G SFP+, 2 x 2.5GG (1x PoE+)")}
                                            {this.renderDeviceInfo("LAN Ports", "4 x 1G, 2 x 10G SFP+")}
                                            {this.renderDeviceInfo("Client Recommendation", "750 Devices")}
                                            {this.renderDeviceInfo("Recommended Max VPN Tunnels", "500 Tunnels")}
                                            {this.renderDeviceInfo("Stateful Firewall Throughput", "3 Gbps")}
                                            {this.renderDeviceInfo("Advanced Security Throughput", "2.5 Gbps")}
                                            {this.renderDeviceInfo("Power", "Dual 250W Power Supplies (included)")}
                                            <h6 className="switchInfoHeader margin10 orderingInformation">
                                              Ordering Information:
                                            </h6>
                                            <p>{"1 x " + model + "-HW"}</p>
                                            {this.renderSecurityLicenseSKU(model)}
                                            {this.renderMILicenseSKU("M")}
                                            {this.renderTransceiverSKU()}
                                          </div>
                                            <div className='datasheetContainer'>
                                              <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Overviews_and_Specifications/MX95%2F%2F105_Datasheet">Datasheet</a>
                                              <p className='datasheetDivider'> | </p>
                                              <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX95%2F%2FMX105_Series_Hardware_Installation_Guide">Installation</a>
                                            </div>
                                          </div>
                                          : this.state.switchModel == "MX250" && model == this.state.switchModel
                                            ? <div><div className="switchInfoSelected">
                                              {this.renderDeviceInfo("WAN Ports", "2 x 10G SFP+")}
                                              {this.renderDeviceInfo("LAN Ports", "8 x 1G, 8 x 1G SFP, 8 x 10G SFP+")}
                                              {this.renderDeviceInfo("Client Recommendation", "2,000 Devices")}
                                              {this.renderDeviceInfo("Recommended Max VPN Tunnels", "1,000 Tunnels")}
                                              {this.renderDeviceInfo("Stateful Firewall Throughput", "4 Gbps")}
                                              {this.renderDeviceInfo("Advanced Security Throughput", "3 Gbps")}
                                              {this.renderDeviceInfo("Power", "Dual 250W Power Supplies (included)")}
                                              <h6 className="switchInfoHeader margin10 orderingInformation">
                                                Ordering Information:
                                              </h6>
                                              <p>{"1 x " + model + "-HW"}</p>
                                              {this.renderSecurityLicenseSKU(model)}
                                              {this.renderMILicenseSKU("L")}
                                              {this.renderTransceiverSKU()}
                                            </div>
                                              <div className='datasheetContainer'>
                                                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mx-family-datasheet/?file">Datasheet</a>
                                                <p className='datasheetDivider'> | </p>
                                                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX250_Installation_Guide">Installation</a>
                                              </div>
                                            </div>
                                            : this.state.switchModel == "MX450" && model == this.state.switchModel
                                              ? <div><div className="switchInfoSelected">
                                                {this.renderDeviceInfo("WAN Ports", "2 x 10G SFP+")}
                                                {this.renderDeviceInfo("LAN Ports", "8 x 1G, 8 x 1G SFP, 8 x 10G SFP+")}
                                                {this.renderDeviceInfo("Client Recommendation", "10,000 Devices")}
                                                {this.renderDeviceInfo("Recommended Max VPN Tunnels", "1,500 Tunnels")}
                                                {this.renderDeviceInfo("Stateful Firewall Throughput", "5 Gbps")}
                                                {this.renderDeviceInfo("Advanced Security Throughput", "6.5 Gbps")}
                                                {this.renderDeviceInfo("Power", "Dual 250W Power Supplies (included)")}
                                                <h6 className="switchInfoHeader margin10 orderingInformation">
                                                  Ordering Information:
                                                </h6>
                                                <p>{"1 x " + model + "-HW"}</p>
                                                {this.renderSecurityLicenseSKU(model)}
                                                {this.renderMILicenseSKU("XL")}
                                                {this.renderTransceiverSKU()}
                                              </div>
                                                <div className='datasheetContainer'>
                                                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mx-family-datasheet/?file">Datasheet</a>
                                                  <p className='datasheetDivider'> | </p>
                                                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX450_Installation_Guide">Installation</a>
                                                </div>
                                              </div>
                                              : this.state.switchModel == "vMX Small" && model == this.state.switchModel
                                                ? <div><div className="switchInfoSelected">
                                                  {this.renderDeviceInfo("Recommended Max VPN Tunnels", "50 Tunnels")}
                                                  {this.renderDeviceInfo("Max Site-to-Site VPN Throughput", "200 Mbps")}
                                                  {this.renderDeviceInfo("Max Client VPN Tunnels", "50 Tunnels")}
                                                  {this.renderDeviceInfo("Supported Cloud Platforms", "")}
                                                  <a href="https://aws.amazon.com/ec2/pricing/" target="_blank" title="Amazon Web Services"><img src="/img/platform-icons/aws.png" alt="AWS" className="cloudPlaformIcon" /></a>
                                                  <a href="https://azure.microsoft.com/en-us/pricing/calculator/" target="_blank" title="Microsoft Azure"><img src="/img/platform-icons/azure.png" alt="Azure" className="cloudPlaformIcon" /></a>
                                                  <a href="https://cloud.google.com/pricing" target="_blank" title="Google Cloud Platform"><img src="/img/platform-icons/google.png" alt="Google Cloud Platform" className="cloudPlaformIcon" /></a>
                                                  <a href="https://www.alibabacloud.com/pricing" target="_blank" title="Alibaba Cloud"><img src="/img/platform-icons/alibaba.png" alt="Alibaba Cloud" className="cloudPlaformIcon" /></a>
                                                  <h6 className="switchInfoHeader margin10 orderingInformation">
                                                    Ordering Information:
                                                  </h6>
                                                  {this.renderVirtualLicenseSKU("S")}
                                                </div>
                                                  <div className='datasheetContainer'>
                                                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mx-family-datasheet/?file">Datasheet</a>
                                                    {/* <p className='datasheetDivider'> | </p>
                                                    <a className='installation' target="_blank" rel="noopener noreferrer" href="">Installation</a> */}
                                                  </div>
                                                </div>
                                                : this.state.switchModel == "vMX Medium" && model == this.state.switchModel
                                                  ? <div><div className="switchInfoSelected">
                                                    {this.renderDeviceInfo("Recommended Max VPN Tunnels", "250 Tunnels")}
                                                    {this.renderDeviceInfo("Max Site-to-Site VPN Throughput", "500 Mbps")}
                                                    {this.renderDeviceInfo("Max Client VPN Tunnels", "250 Tunnels")}
                                                    {this.renderDeviceInfo("Supported Cloud Platforms", "")}
                                                    <a href="https://aws.amazon.com/ec2/pricing/" target="_blank" title="Amazon Web Services"><img src="/img/platform-icons/aws.png" alt="AWS" className="cloudPlaformIcon" /></a>
                                                    <a href="https://azure.microsoft.com/en-us/pricing/calculator/" target="_blank" title="Microsoft Azure"><img src="/img/platform-icons/azure.png" alt="Azure" className="cloudPlaformIcon" /></a>
                                                    <a href="https://cloud.google.com/pricing" target="_blank" title="Google Cloud Platform"><img src="/img/platform-icons/google.png" alt="Google Cloud Platform" className="cloudPlaformIcon" /></a>
                                                    <a href="https://www.alibabacloud.com/pricing" target="_blank" title="Alibaba Cloud"><img src="/img/platform-icons/alibaba.png" alt="Alibaba Cloud" className="cloudPlaformIcon" /></a>
                                                    <h6 className="switchInfoHeader margin10 orderingInformation">
                                                      Ordering Information:
                                                    </h6>
                                                    {this.renderVirtualLicenseSKU("M")}
                                                  </div>
                                                    <div className='datasheetContainer'>
                                                      <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mx-family-datasheet/?file">Datasheet</a>
                                                      {/* <p className='datasheetDivider'> | </p>
                                                      <a className='installation' target="_blank" rel="noopener noreferrer" href="">Installation</a> */}
                                                    </div>
                                                  </div>
                                                  : this.state.switchModel == "vMX Large" && model == this.state.switchModel
                                                    ? <div><div className="switchInfoSelected">
                                                      {this.renderDeviceInfo("Recommended Max VPN Tunnels", "1,000 Tunnels")}
                                                      {this.renderDeviceInfo("Max Site-to-Site VPN Throughput", "1 Gbps")}
                                                      {this.renderDeviceInfo("Max Client VPN Tunnels", "500 Tunnels")}
                                                      {this.renderDeviceInfo("Supported Cloud Platforms", "")}
                                                      <a href="https://aws.amazon.com/ec2/pricing/" target="_blank" title="Amazon Web Services"><img src="/img/platform-icons/aws.png" alt="AWS" className="cloudPlaformIcon" /></a>
                                                      <a href="https://www.alibabacloud.com/pricing" target="_blank" title="Alibaba Cloud"><img src="/img/platform-icons/alibaba.png" alt="Alibaba Cloud" className="cloudPlaformIcon" /></a>
                                                      <h6 className="switchInfoHeader margin10 orderingInformation">
                                                        Ordering Information:
                                                      </h6>
                                                      {this.renderVirtualLicenseSKU("L")}
                                                    </div>
                                                      <div className='datasheetContainer'>
                                                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mx-family-datasheet/?file">Datasheet</a>
                                                        {/* <p className='datasheetDivider'> | </p>
                                                        <a className='installation' target="_blank" rel="noopener noreferrer" href="">Installation</a> */}
                                                      </div>
                                                    </div>
                                                    : ("")
        }
      </div>
    </div>);
  }
  switchPickingLogic() {
    return (<div>
      {
        <div>
          {
            this.state.license != "ENT" && this.state.license != "Virtual" && this.state.license != null
              ? (securityToggle = true)
              : (securityToggle = false)
          }
          {
            devices.map(
              (switchmodel) => this.state.devices != null && switchmodel.devices < this.state.devices
                ? ""
                : this.state.vpnCount != null && switchmodel.vpnTunnels < this.state.vpnCount
                  ? ""

                  : securityToggle == true && switchmodel.security == 0
                    ? ""
                    // dont let z4 show up when enabling secure SD-WAN plus licensing
                    : securityToggle == true && switchmodel.security == 300 && this.state.license == 'SDW'
                    ? ""
                    : securityToggle == false && this.state.speedCount != null && switchmodel.maxSpeed < this.state.speedCount
                      ? ""
                      : securityToggle == true && this.state.speedCount != null && switchmodel.security < this.state.speedCount
                        ? ""
                        : this.state.license != "Virtual" && this.state.license != null && switchmodel.virtual == true
                          ? ""
                          : this.state.license == "Virtual" && switchmodel.virtual == false
                            ? ""
                            : this.state.merakiInsight == true && switchmodel.virtual == true
                              ? ""
                              : switchmodel.wan1 != this.state.uplink && switchmodel.wan2 != this.state.uplink && this.state.uplink != null
                                ? ""
                                : this.state.sim == true && switchmodel.sim != true
                                  ? ""
                                  : this.state.wireless == true && switchmodel.wireless != true
                                    ? ""
                                    : this.state.poe == true && switchmodel.poe != true
                                      ? ""
                                      : this.state.rpsu == true && switchmodel.rpsu != true
                                        ? ""
                                        : this.state.rfan == true && switchmodel.rfan != true
                                          ? ""
                                          : this.renderSwitchModel(switchmodel.model))
          }
        </div>
      }
    </div>);
  }

  optionBubbleLogic() {
    return (<div>
      {
        this.state.devices != null && this.state.devices != ""
          ? (<div className="optionBubble" onClick={() => this.setState({ devices: "" })}>
            {this.state.devices + " Devices"}
          </div>)
          : ("")
      }
      {
        this.state.vpnCount != null && this.state.vpnCount != ""
          ? (<div className="optionBubble" onClick={() => this.setState({ vpnCount: "" })}>
            {this.state.vpnCount + " VPN Tunnels"}
          </div>)
          : ("")
      }
      {
        this.state.speedCount != null && this.state.speedCount != ""
          ? (<div className="optionBubble" onClick={() => this.setState({ speedCount: "" })}>
            {"Throughput: " + this.state.speedCount + " Mbps"}
          </div>)
          : ("")
      }

      {
        this.state.license != null && this.state.licenseTerm != null
          ? (<div className="optionBubble" onClick={() => this.setState({ license: null, licenseTerm: null })}>
            {"License: " + this.state.license + " " + this.state.licenseTerm + " YR"}
          </div>)
          : this.state.license != null
            ? (<div className="optionBubble" onClick={() => this.setState({ license: null, licenseTerm: null })}>
              {"License: " + this.state.license}
            </div>)
            : ("")
      }

      {
        this.state.uplink != null
          ? (<div className="optionBubble" onClick={() => this.setState({ uplink: null })}>
            {"WAN: " + this.state.uplink}
          </div>)
          : ("")
      }
      {
        this.state.wireless != null
          ? (<div className="optionBubble" onClick={() => this.setState({ wireless: null })}>
            {"Wireless"}
          </div>)
          : ("")
      }
      {
        this.state.sim != null
          ? (<div className="optionBubble" onClick={() => this.setState({ sim: null })}>
            {"SIM Slot"}
          </div>)
          : ("")
      }
      {
        this.state.poe != null
          ? (<div className="optionBubble" onClick={() => this.setState({ poe: null })}>
            {"PoE"}
          </div>)
          : ("")
      }
      {
        this.state.rpsu != null
          ? (<div className="optionBubble" onClick={() => this.setState({ rpsu: null })}>
            {"Redundant PSU"}
          </div>)
          : ("")
      }
      {
        this.state.rfan != null
          ? (<div className="optionBubble" onClick={() => this.setState({ rfan: null })}>
            {"Redundant Fan"}
          </div>)
          : ("")
      }

      {
        this.state.miTerm != null
          ? (<div className="optionBubble" onClick={() => this.setState({ merakiInsight: null, miTerm: null })}>
            {"Meraki Insight: " + this.state.miTerm + " YR"}
          </div>)
          : ("")
      }
    </div>);
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ OPTIONS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  handleChange(event) {
    this.setState({
      devices: event.target.value.replace(/\D/, "")
    });
  }
  renderOptionDevices() {
    return (<div>
      <div>
        {
          <form className="optionForm" onSubmit={(e) => {
            e.preventDefault();
          }}>
            <div class="optionGroup">
              <input className={this.state.devices != "" && this.state.devices != null
                ? "optionInputClicked"
                : "optionInput"
              } type="text" name="name" autocomplete="off" required="required" value={this.state.devices} onChange={this.handleChange} onFocus={() => this.setState({ devicesMain: true })} onBlur={() => this.setState({ devicesMain: false })} />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label className="optionLabel">Number of Devices</label>
            </div>
          </form>
        }
      </div>
      {
        this.state.devicesMain == true
          ? (<div className="secondaryOptionsContainer">
            <h4>Client Recommendations</h4>
            <h5>
              There is no hard limit on the number of client devices that can connect to the MX Security Appliances. However, exceeding the client count in the recommeded appliance may result in unexpected performance.
            </h5>
          </div>)
          : (<div></div>)
      }
    </div>);
  }

  handleVPNChange(event) {
    this.setState({
      vpnCount: event.target.value.replace(/\D/, "")
    });
  }
  renderOptionMaxVPN() {
    return (<div>
      <div>
        {
          <form className="optionForm" onSubmit={(e) => {
            e.preventDefault();
          }}>
            <div class="optionGroup">
              <input className={this.state.vpnCount != "" && this.state.vpnCount != null
                ? "optionInputClicked"
                : "optionInput"
              } type="text" name="name" autocomplete="off" required="required" value={this.state.vpnCount} onChange={this.handleVPNChange} onFocus={() => this.setState({ vpnMain: true })} onBlur={() => this.setState({ vpnMain: false })} />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label className="optionLabel">Number of VPN Tunnels</label>
            </div>
          </form>
        }
      </div>
      {
        this.state.vpnMain == true
          ? (<div className="secondaryOptionsContainer">
            <h4>Recommended maximum concurrent VPN tunnels</h4>
            <h5>
              A connection between two peers can contain up to four tunnels, depending on the number of MX uplinks at each site. This should be taken into consideration when making VPN sizing decisions.
            </h5>
          </div>)
          : (<div></div>)
      }
    </div>);
  }

  handleSpeedChange(event) {
    this.setState({
      speedCount: event.target.value.replace(/\D/, "")
    });
  }
  renderOptionSpeed() {
    return (<div>
      <div>
        {
          <form className="optionForm" onSubmit={(e) => {
            e.preventDefault();
          }}>
            <div class="optionGroup">
              <input className={this.state.speedCount != "" && this.state.speedCount != null
                ? "optionInputClicked"
                : "optionInput"
              } type="text" name="name" autocomplete="off" required="required" value={this.state.speedCount} onChange={this.handleSpeedChange} onFocus={() => this.setState({ speedMain: true })} onBlur={() => this.setState({ speedMain: false })} />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label className="optionLabel">Throughput (Mbps)</label>
            </div>
          </form>
        }
      </div>
      {
        this.state.speedMain == true
          ? (<div className="secondaryOptionsContainer">
            <h4>Network performance benchmarks</h4>
            <h5>
              Industry standard benchmarks are designed to help you compare MX security appliances to firewalls from other vendors. These tests assume perfect network conditions with ideal traffic patterns. When measuring maximum throughput for a certain feature, all other features are disabled. Actual results in production networks will vary.
            </h5>
          </div>)
          : (<div></div>)
      }
    </div>);
  }

  renderOptionEnterprise() {
    return (<div>
      <div onClick={(
      ) => this.state.license != "ENT"
          ? this.setState({ license: "ENT", licenseTerm: null })
          : this.setState({ license: null, licenseTerm: null })
      } className={this.state.license == "ENT"
        ? "optionClicked"
        : "option"}>
        Enterprise
      </div>
      {
        this.state.license == "ENT"
          ? (<div className="secondaryOptionsContainer">
            <h4>
              Please select the duration for your Enterprise license (Years)
            </h4>
            <h5>
              • Essential SD-WAN
              <br></br>• Secure connectivity & basic security
            </h5>
            <div className="secondaryOptionsContainer2">
              {this.renderSecondaryLicenseOptions(1)}
              {this.renderSecondaryLicenseOptions(3)}
              {this.renderSecondaryLicenseOptions(5)}
              {this.renderSecondaryLicenseOptions(7)}
              {this.renderSecondaryLicenseOptions(10)}
            </div>
          </div>)
          : (<div></div>)
      }
    </div>);
  }

  renderOptionAdvance() {
    return (<div>
      <div onClick={(
      ) => this.state.license != "SEC"
          ? this.setState({ license: "SEC", licenseTerm: null })
          : this.setState({ license: null, licenseTerm: null })
      } className={this.state.license == "SEC"
        ? "optionClicked"
        : "option"}>
        Advance Security
      </div>
      {
        this.state.license == "SEC"
          ? (<div className="secondaryOptionsContainer">
            <h4>
              Please select the duration for your Advance Security license (Years)
            </h4>
            <h5>
              • All enterprise features
              <br></br>• Fully featured unified threat management
            </h5>
            <div className="secondaryOptionsContainer2">
              {this.renderSecondaryLicenseOptions(1)}
              {this.renderSecondaryLicenseOptions(3)}
              {this.renderSecondaryLicenseOptions(5)}
              {this.renderSecondaryLicenseOptions(7)}
              {this.renderSecondaryLicenseOptions(10)}
            </div>
          </div>)
          : (<div></div>)
      }
    </div>);
  }

  renderOptionSDWAN() {
    return (<div>
      <div onClick={(
      ) => this.state.license != "SDW"
          ? this.setState({ license: "SDW", licenseTerm: null, merakiInsight: null, miTerm: null })
          : this.setState({ license: null, licenseTerm: null })
      } className={this.state.license == "SDW"
        ? "optionClicked"
        : "option"}>
        Secure SD-WAN Plus
      </div>
      {
        this.state.license == "SDW"
          ? (<div className="secondaryOptionsContainer">
            <h4>
              Please select the duration for your SD-WAN Plus license (Years)
            </h4>
            <h5>
              • All advanced security features
              <br></br>• Advanced analytics with machine learning powered by Meraki Insight<br></br>• Smart SaaS QoE<br></br>• Tag-Based Segmentation
            </h5>
            <div className="secondaryOptionsContainer2">
              {this.renderSecondaryLicenseOptions(1)}
              {this.renderSecondaryLicenseOptions(3)}
              {this.renderSecondaryLicenseOptions(5)}
              {this.renderSecondaryLicenseOptions(7)}
              {this.renderSecondaryLicenseOptions(10)}
            </div>
          </div>)
          : (<div></div>)
      }
    </div>);
  }

  renderOptionVirtual() {
    return (<div>
      <div onClick={(
      ) => this.state.license != "Virtual"
          ? this.setState({ license: "Virtual", licenseTerm: null })
          : this.setState({ license: null, licenseTerm: null })
      } className={this.state.license == "Virtual"
        ? "optionClicked"
        : "option"}>
        Virtual
      </div>
      {
        this.state.license == "Virtual"
          ? (<div className="secondaryOptionsContainer">
            <h4>
              Please select the duration for your Virtual Enterprise license (Years)
            </h4>
            <h5>
              Virtual MX (vMX) is a virtual instance of a Meraki security & SD-WAN appliance. It is dedicated to providing the simple configuration benefits of site-to-site Auto VPN for organizations running or migrating IT services to public cloud environments.
            </h5>
            <div className="secondaryOptionsContainer2">
              {this.renderSecondaryLicenseOptions(1)}
              {this.renderSecondaryLicenseOptions(3)}
              {this.renderSecondaryLicenseOptions(5)}
            </div>
          </div>)
          : (<div></div>)
      }
    </div>);
  }

  renderSecondaryLicenseOptions(years) {
    return (<div onClick={(
    ) => this.state.licenseTerm != years
        ? this.setState({ licenseTerm: years })
        : this.setState({ licenseTerm: null })
    } className={this.state.licenseTerm == years
      ? "secondaryOptionClicked"
      : "secondaryOption"
    }>
      <h4>{years}</h4>
    </div>);
  }

  renderOptionWireless() {
    return (<div>
      <div onClick={(
      ) => this.state.wireless != true
          ? this.setState({ wireless: true })
          : this.setState({ wireless: null })
      } className={this.state.wireless
        ? "optionClicked"
        : "option"}>
        Wireless
      </div>
    </div>);
  }
  renderOptionSIM() {
    return (<div>
      <div onClick={(
      ) => this.state.sim != true
          ? this.setState({ sim: true })
          : this.setState({ sim: null })
      } className={this.state.sim
        ? "optionClicked"
        : "option"}>
        Built-in LTE
      </div>
    </div>);
  }

  // renderSFPOptions() {
  //   return (<div className="secondaryOptionsContainer">
  //     <h4>Please select a fiber transceivers:</h4>
  //     <div>
  //       {
  //         this.state.uplink == '1G SFP'
  //           ? <div>
  //             <div onClick={(
  //             ) => this.state.transceiver != 'MA-SFP-1GB-TX'
  //                 ? this.setState({ transceiver: 'MA-SFP-1GB-TX' })
  //                 : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-1GB-TX'
  //                   ? 'secondaryOptionClicked'
  //                   : 'secondaryOption'}>
  //               <div className='secondaryLeftSide'>MA-SFP-1GB-TX</div>
  //               <div className='secondaryRightSide'>Ethernet</div>
  //             </div>
  //             <div onClick={(
  //             ) => this.state.transceiver != 'MA-SFP-1GB-SX'
  //                 ? this.setState({ transceiver: 'MA-SFP-1GB-SX' })
  //                 : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-1GB-SX'
  //                   ? 'secondaryOptionClicked'
  //                   : 'secondaryOption'}>
  //               <div className='secondaryLeftSide'>MA-SFP-1GB-SX (1km)</div>
  //               <div className='secondaryRightSide'>MMF</div>
  //             </div>
  //             <div onClick={(
  //             ) => this.state.transceiver != 'MA-SFP-1GB-LX10'
  //                 ? this.setState({ transceiver: 'MA-SFP-1GB-LX10' })
  //                 : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-1GB-LX10'
  //                   ? 'secondaryOptionClicked'
  //                   : 'secondaryOption'}>
  //               <div className='secondaryLeftSide'>MA-SFP-1GB-LX10 (550km/10km)</div>
  //               <div className='secondaryRightSide'>MMF/SMF</div>
  //             </div>
  //           </div>

  //           : this.state.uplink == '10G SFP+'
  //             ? <div>
  //               <div onClick={(
  //               ) => this.state.transceiver != 'MA-SFP-1GB-TX'
  //                   ? this.setState({ transceiver: 'MA-SFP-1GB-TX' })
  //                   : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-1GB-TX'
  //                     ? 'secondaryOptionClicked'
  //                     : 'secondaryOption'}>
  //                 <div className='secondaryLeftSide'>MA-SFP-1GB-TX</div>
  //                 <div className='secondaryRightSide'>Ethernet</div>
  //               </div>
  //               <div onClick={(
  //               ) => this.state.transceiver != 'MA-SFP-10GB-SR'
  //                   ? this.setState({ transceiver: 'MA-SFP-10GB-SR' })
  //                   : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-10GB-SR'
  //                     ? 'secondaryOptionClicked'
  //                     : 'secondaryOption'}>
  //                 <div className='secondaryLeftSide'>MA-SFP-10GB-SR (400m)</div>
  //                 <div className='secondaryRightSide'>MMF</div>
  //               </div>
  //               <div onClick={(
  //               ) => this.state.transceiver != 'MA-SFP-10GB-LRM'
  //                   ? this.setState({ transceiver: 'MA-SFP-10GB-LRM' })
  //                   : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-10GB-LRM'
  //                     ? 'secondaryOptionClicked'
  //                     : 'secondaryOption'}>
  //                 <div className='secondaryLeftSide'>MA-SFP-10GB-LRM (220m/220m)</div>
  //                 <div className='secondaryRightSide'>MMF/SMF</div>
  //               </div>
  //               <div onClick={(
  //               ) => this.state.transceiver != 'MA-SFP-10GB-LR'
  //                   ? this.setState({ transceiver: 'MA-SFP-10GB-LR' })
  //                   : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-10GB-LR'
  //                     ? 'secondaryOptionClicked'
  //                     : 'secondaryOption'}>
  //                 <div className='secondaryLeftSide'>MA-SFP-10GB-LR (10km)</div>
  //                 <div className='secondaryRightSide'>SMF</div>
  //               </div>
  //             </div>
  //             : this.state.uplink == '40sfp'
  //               ? <div>
  //                 <div onClick={(
  //                 ) => this.state.transceiver != 'MA-QSFP-40G-SR4'
  //                     ? this.setState({ transceiver: 'MA-QSFP-40G-SR4' })
  //                     : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-QSFP-40G-SR4'
  //                       ? 'secondaryOptionClicked'
  //                       : 'secondaryOption'}>
  //                   <div className='secondaryLeftSide'>MA-QSFP-40G-SR4 (150m)</div>
  //                   <div className='secondaryRightSide'>MMF</div>
  //                 </div>
  //                 <div onClick={(
  //                 ) => this.state.transceiver != 'MA-QSFP-40G-CSR4'
  //                     ? this.setState({ transceiver: 'MA-QSFP-40G-CSR4' })
  //                     : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-QSFP-40G-CSR4'
  //                       ? 'secondaryOptionClicked'
  //                       : 'secondaryOption'}>
  //                   <div className='secondaryLeftSide'>MA-QSFP-40G-CSR4 (400m)</div>
  //                   <div className='secondaryRightSide'>MMF</div>
  //                 </div>
  //                 <div onClick={(
  //                 ) => this.state.transceiver != 'MA-QSFP-40G-SR-BD'
  //                     ? this.setState({ transceiver: 'MA-QSFP-40G-SR-BD' })
  //                     : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-QSFP-40G-SR-BD'
  //                       ? 'secondaryOptionClicked'
  //                       : 'secondaryOption'}>
  //                   <div className='secondaryLeftSide'>MA-QSFP-40G-SR-BD (150m)</div>
  //                   <div className='secondaryRightSide'>MMF</div>
  //                 </div>
  //                 <div onClick={(
  //                 ) => this.state.transceiver != 'MA-QSFP-40G-LR4'
  //                     ? this.setState({ transceiver: 'MA-QSFP-40G-LR4' })
  //                     : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-QSFP-40G-LR4'
  //                       ? 'secondaryOptionClicked'
  //                       : 'secondaryOption'}>
  //                   <div className='secondaryLeftSide'>MA-QSFP-40G-LR4 (10km)</div>
  //                   <div className='secondaryRightSide'>SMF</div>
  //                 </div>
  //               </div>
  //               : this.state.uplink == '100sfp'
  //                 ? <div>
  //                   <div onClick={(
  //                   ) => this.state.transceiver != 'MA-QSFP-100G-SR4'
  //                       ? this.setState({ transceiver: 'MA-QSFP-100G-SR4' })
  //                       : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-QSFP-100G-SR4'
  //                         ? 'secondaryOptionClicked'
  //                         : 'secondaryOption'}>
  //                     <div className='secondaryLeftSide'>MA-QSFP-100G-SR4 (100m)</div>
  //                     <div className='secondaryRightSide'>MMF</div>
  //                   </div>
  //                   <div onClick={(
  //                   ) => this.state.transceiver != 'MA-QSFP-100G-LR4'
  //                       ? this.setState({ transceiver: 'MA-QSFP-100G-LR4' })
  //                       : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-QSFP-100G-LR4'
  //                         ? 'secondaryOptionClicked'
  //                         : 'secondaryOption'}>
  //                     <div className='secondaryLeftSide'>MA-QSFP-100G-LR4 (10km)</div>
  //                     <div className='secondaryRightSide'>SMF</div>
  //                   </div>
  //                 </div>
  //                 : ""
  //       }
  //     </div>
  //   </div>)
  // }

  renderOption1() {
    return (<div>
      <div onClick={(
      ) => this.state.uplink != '1G'
          ? this.setState({ uplink: '1G', transceiver: null })
          : this.setState({ uplink: null })
      } className={this.state.uplink == '1G'
        ? "optionClicked"
        : "option"}>
        <div className="leftSide">1 Gbps</div>
        <div className='rightSide'>Ethernet</div>
      </div>
    </div>);
  }
  renderOption1SFP() {
    return (<div>
      <div onClick={(
      ) => this.state.uplink != '1G SFP'
          ? this.setState({ uplink: '1G SFP', transceiver: null })
          : this.setState({ uplink: null })
      } className={this.state.uplink == '1G SFP'
        ? "optionClicked"
        : "option"}>
        <div className="leftSide">1 Gbps</div>
        <div className='rightSide'>SFP</div>
      </div>

      {/* {
        this.state.uplink == '1G SFP'
          ? this.renderSFPOptions()
          : ""
      } */}
    </div>);
  }

  renderOption2point5() {
    return (<div>
      <div onClick={(
      ) => this.state.uplink != '2.5G'
          ? this.setState({ uplink: '2.5G', transceiver: null })
          : this.setState({ uplink: null })
      } className={this.state.uplink == '2.5G'
        ? "optionClicked"
        : "option"}>
        <div className="leftSide">2.5G Gbps</div>
        <div className='rightSide'>Ethernet</div>
      </div>
    </div>);
  }

  renderOption10() {
    return (<div>
      <div onClick={(
      ) => this.state.uplink != '10G SFP+'
          ? this.setState({ uplink: '10G SFP+', transceiver: null })
          : this.setState({ uplink: null })
      } className={this.state.uplink == '10G SFP+'
        ? "optionClicked"
        : "option"}>
        <div className="leftSide">10 Gbps</div>
        <div className='rightSide'>SFP+</div>
      </div>
      {/* {
        this.state.uplink == '10G SFP+'
          ? this.renderSFPOptions()
          : ""
      } */}
    </div>);
  }

  renderOptionPSU() {
    return (<div>
      <div onClick={(
      ) => this.state.rpsu != true
          ? this.setState({ rpsu: true })
          : this.setState({ rpsu: null })
      } className={this.state.rpsu == true
        ? "optionClicked"
        : "option"}>
        Power Supply
      </div>
    </div>);
  }

  renderOptionFan() {
    return (<div>
      <div onClick={(
      ) => this.state.rfan != true
          ? this.setState({ rfan: true })
          : this.setState({ rfan: null })
      } className={this.state.rfan == true
        ? "optionClicked"
        : "option"}>
        Fan
      </div>
    </div>);
  }

  renderOptionMIYes() {
    return (<div>
      <div onClick={(
      ) => this.state.merakiInsight != true
          ? this.setState({ merakiInsight: true })
          : this.setState({ merakiInsight: null, miTerm: null })
      } className={this.state.merakiInsight == true
        ? "optionClicked"
        : "option"
      }>
        Yes
      </div>
      {
        this.state.merakiInsight == true
          ? (<div className="secondaryOptionsContainer">
            <h4>
              Please select the duration for you Meraki Insight License (Years)
            </h4>
            <h5>
              A license is only required for those networks where Meraki Insight functionality is desired. One license is required per network, regardless of whether that network has a single MX or HA pair. Licenses can be moved between networks, but historical data for the old network will be lost.
            </h5>
            <div className="secondaryOptionsContainer2">
              {this.renderSecondaryMIOptions(1)}
              {this.renderSecondaryMIOptions(3)}
              {this.renderSecondaryMIOptions(5)}
              {this.renderSecondaryMIOptions(7)}
              {this.renderSecondaryMIOptions(10)}
            </div>
          </div>)
          : (<div></div>)
      }
    </div>);
  }

  renderSecondaryMIOptions(years) {
    return (<div onClick={(
    ) => this.state.miTerm != years
        ? this.setState({ miTerm: years })
        : this.setState({ miTerm: null })
    } className={this.state.miTerm == years
      ? "secondaryOptionClicked"
      : "secondaryOption"
    }>
      <h4>{years}</h4>
    </div>);
  }

  renderOptionMINo() {
    return (<div>
      <div onClick={(
      ) => this.state.merakiInsight != false
          ? this.setState({ merakiInsight: false })
          : this.setState({ merakiInsight: null })
      } className={this.state.merakiInsight == false
        ? "optionClicked"
        : "option"
      }>
        No
      </div>
    </div>);
  }

  renderOptionPoe() {
    return (<div>
      <div onClick={(
      ) => this.state.poe != true
          ? this.setState({ poe: true })
          : this.setState({ poe: null })
      } className={this.state.poe == true
        ? "optionClicked"
        : "option"}>
        PoE
      </div>
    </div>);
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ RENDER @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  render() {
    return (<div>
      <div className="form">
        <div className="leftForm">
          {this.optionBubbleLogic()}
          {this.switchPickingLogic()}
        </div>

        <div className="rightFormContainer">
          <div className="rightForm">
            <div className="mainTitle">
              <h6 className='topSubHeader'>Meraki Networking</h6>
              <h1>MX Security & SD-WAN</h1>
              <h3 className='bottomSubHeader'>
                The MX family is an enterprise security & SD-WAN solution designed for distributed deployments that require remote administration.
              </h3>
            </div>

            <div>
              <h2>Choose your license type.</h2>
              <a href="https://documentation.meraki.com/zGeneral_Administration/Licensing/Meraki_MX_Security_and_SD-WAN_Licensing" target="_blank">
                Learn more about our MX Security and SD-WAN licensing
              </a>
              {this.renderOptionEnterprise()}
              {this.renderOptionAdvance()}
              {this.renderOptionSDWAN()}
              {this.renderOptionVirtual()}
            </div>

            {
              <div className={this.state.license == "SDW" || this.state.license == "Virtual"
                ? "disabled"
                : ""}>
                <h2>Would you like to include Meraki Insight?</h2>
                <a href="https://meraki.cisco.com/lib/pdf/meraki_datasheet_mi.pdf" target="_blank">
                  Learn more about Meraki Insight
                </a>
                {this.renderOptionMIYes()}
                {this.renderOptionMINo()}
              </div>
            }

            <div>
              <h2>How many devices will be connecting to the MX?</h2>
              {this.renderOptionDevices()}
            </div>

            <div>
              <h2>How many VPN tunnels will be connecting to the MX?</h2>
              {this.renderOptionMaxVPN()}
            </div>

            <div>
              <h2>What throughput do you require?</h2>
              {this.renderOptionSpeed()}
            </div>

            <div>
              <h2>Choose your features.</h2>
              {this.renderOptionWireless()}
              {this.renderOptionSIM()}
              {this.renderOptionPoe()}
            </div>

            <div>
              <h2>Choose your WAN ports speed.</h2>
              {this.renderOption1()}
              {this.renderOption1SFP()}
              {this.renderOption2point5()}
              {this.renderOption10()}
            </div>

            <div>
              <h2>Which redundant parts do you need?</h2>
              {this.renderOptionPSU()}
              {this.renderOptionFan()}
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}

// ========================================

export default MXPicker;
